<template>
  <chat v-if="channel" :channel="channel" :settings="settings" class="chat" />
</template>

<script>
import Chat from "../../components/chat/chat-overylay";

export default {
  components: {
    Chat,
  },
  data() {
    return {
      channel: "",
      channelData: {},
      settings: {},
    };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const animate = urlParams.get("animate");
    const ttl = urlParams.get("ttl");
    const delay = urlParams.get("delay");
    if (!ttl) {
      this.settings.ttl = 5;
    } else {
      this.settings.ttl = ttl;
    }
    if (!delay) {
      this.settings.delay = 5;
    } else {
      this.settings.delay = delay;
    }
    if (animate == 'true') {
      this.settings.animate = true;
    } else {
      this.settings.animate = false;
    }
    if (this.settings.animate) {

    }
  },
  async mounted() {
    const channel = window.location.pathname
      .substring(1)
      .replace("/chat-overlay", "");
    const channelLookup = await this.channelLookupBySlug(channel)
    this.channel = channelLookup.xid  
  },
};
</script>

<style>
.slide-in {
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
}

@-webkit-keyframes slide {
    100% { left: 0; }
}

@keyframes slide {
    100% { left: 0; }
}
.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}
    
@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(-100%); }
}
.m__username {
  font-size: 1rem;
}
.m__content {
  font-size: 1rem;
}
.input {
  display: none !important;
}
.chat__column-header {
  display: none !important;
}
body.dark-layout {
  background-color: unset !important;
}
.chat__column {
  background: unset !important;
  width: 100% !important;
}
.chat {
  height: calc(100vh);
  min-height: calc(100vh);
}
.chatcolumn-header,
.chatfooter {
  display: none;
}
.chatcolumn,
.messages,
body.dark-layout {
  background: #0d0e1800 !important;
}
.messages > div {
  background-color: #000000e6;
}
.mcontent {
  font-weight: medium;
  color: #ffffff;
  letter-spacing: 1px;
}
.message {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
