<!-- eslint-disable -->
<!--
input component
- emoji
- gifs
- sendmessage (disabled until client)
- reply preview
- props: client, mention (watch prop change), errors

message
- v-switch (types)
- reply_to ({message})

user-card
- xid

widget component
- polls/voting
- top chatter? fixed to top
- resize observer

gif (for messages)
- use preview src
- on hover (or chat focused?) use src
- flag? this.autoPlay
-->
<template>
  <div class="chat__column">
    <div class="chat__column-header">
      <!---    <feather-icon
        size="20"
        icon="ChevronsRightIcon"
        class="collapseListIcon"
      />--->
      <center
        style="position: relative; top: 5px; text-align: center !important; font-weight: bold;"
      >
        {{ activeElement }}
      </center>
      <a
        @click.prevent="openSettings"
        title="Chat Settings"
        v-if="!viewerListShow && !settingsShow"
      >
        <feather-icon size="20" icon="SettingsIcon" class="settingsIcon" />
      </a>
      <a
        @click.prevent="openViewerList"
        title="Viewer List"
        v-if="!viewerListShow && !settingsShow"
      >
        <feather-icon size="20" icon="ListIcon" class="viewerListIcon" />
      </a>
      <a
        @click.prevent="closeViewerList"
        v-if="viewerListShow || settingsShow"
        title="Viewer List"
      >
        <feather-icon size="20" icon="XSquareIcon" class="viewerListIcon" />
      </a>
    </div>
    <div class="chat__column-viewerList" v-if="viewerListShow">
      <div
        v-for="viewer in chatters"
        :key="viewer.chat_xid"
        class="ml-1"
        style="margin-bottom: 5px;"
      >
        <b-avatar
          size="35"
          :src="
            `https://assets.brimecdn.com/brime/users/${viewer.user_xid}/avatar`
          "
          variant="light-primary"
          class="badge-minimal mr-1"
        /><a :href="`/${viewer.username}`" target="_blank"
          ><span :style="`font-weight: bold; color: ${viewer.chat_color}`">{{
            viewer.display_name
          }}</span></a
        ><span style="font-weight: bold;" v-if="viewer.guest">Guest</span>
      </div>
    </div>
    <div class="chat__column-viewerList" v-if="settingsShow">
    <b-form-checkbox
      name="check-button"
      class="ml-1"
      :checked="ding"
      @change="dingToggle"
      switch
      inline
    >
      Ding on new messages
    </b-form-checkbox>
    </div>
    <div class="messages" data-pause-scrolling="false">
      <chat-message
        v-for="message in messages"
        :key="message.xid"
        :message="message"
        :active="reply_target ? reply_target.xid == message.xid : false"
        :highlight="highlight === message.xid"
        :mentioned="
          message.content.meta.mentions.findIndex(
            e => e.username == $auth.user.nickname,
          ) != -1 ||
            (message.reply &&
              message.reply.user.username == $auth.user.nickname)
        "
        @reply="setReplyTarget"
        @scrollReply="scrollToReply"
        @mention="setMentionTarget"
        @showUser="setUserTarget"
        @showLink="setLinkTarget"
        @context="setContext"
      />
    </div>
    <div class="chat__footer">
      <chat-reply
        v-if="reply_target"
        :reply="reply_target"
        @removeReply="removeReplyTarget"
      />
      <chat-input
        @resetMention="resetMentionTarget"
        @send="sendMessage"
        @sendGif="sendGif"
        :mentioned="mention"
      />
    </div>
    <user-card :target="user_target" :channel="channel" />
    <link-card :target="link_target" />
    <chat-context @reply="setReplyTarget" @mention="setMentionTarget" @close="setContext" @command="sendCommand" v-if="context" :context="context" />
  </div>
</template>


<style>
.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}
    
@keyframes slide-in {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
}
    
@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(-100%); }
}
</style>

<style scoped>

.chat__column-viewerList {
  margin-top: 10px;
  overflow: auto;
}
.chat__column-header {
  border-top: 1px;
  border-top-style: solid;
  border-top-color: #111727;
  height: 30px;
  background: #283046;
}
.viewerListIcon {
  position: absolute;
  top: 5px;
  right: 10px;
}
.settingsIcon {
  position: absolute;
  top: 5px;
  left: 10px;
}
.collapseListIcon {
  position: absolute;
  left: 10px;
  top: 5px;
}
.chat__column {
  display: flex;
  flex-direction: column;
  background: #111727;
  padding: 0;
}

@media screen and (min-width: 769px) {
  .chat__column {
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    top: var(--headerHeight);
    height: calc(100% - var(--headerHeight));
    width: max(20vw, 250px);
  }
}

@media screen and (max-width: 768px) {
  .viewerListIcon {
    top: unset;
    right: 5px !important;
    transform: translateY(-15px);
  }
  .chat__column {
    width: 100%;
    flex: 1;
    margin: 0;
    overflow: hidden;
  }
}

.messages {
  flex: 1;
  overflow: auto;
     overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* Internet Explorer 10+ */   
}
.messages::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
      }

.input {
  flex-shrink: 0;
  background: #283046;
  display: flex;
  align-items: center;
  width: calc(100% - 1rem);
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
  border: 2px solid transparent;
  transition: border-color 0.2s;
  position: relative;
}
</style>

<script>
/* eslint-disable */
import ChatMessage from './chat-message-overlay.vue'
import ChatInput from './chat-input.vue'
import mqtt from 'mqtt'
import ChatReply from './chat-reply.vue'
import UserCard from './user-card.vue'
import LinkCard from './link-card.vue'
const tmi = require('tmi.js')
import { BAvatar, BFormCheckbox  } from 'bootstrap-vue'
import ChatContext from './chat-context.vue'

export default {
  props: ['channel', 'user', 'token', 'mobile', 'settings'],
  data() {
    return {
      chatLang: 'en-us',
      ding: null,
      context: false,
      chatters: [],
      activeElement: 'STREAM CHAT',
      viewerListShow: false,
      settingsShow: false,
      mention: false,
      highlight: false,
      messages: [],
      messageTTL: {},
      MAX_MESSAGES: 100,
      reply_target: false,
      user_target: false,
      link_target: false,
    }
  },
   watch: {
    channel: [{
      handler: 'getChannel'
    }]
  },
  methods: {
    dingToggle(){
      if(this.ding == false){
        this.ding = true
        localStorage.setItem('chat-ding', true);
      } else {
        this.ding = false
        localStorage.setItem('chat-ding', false);
      }
    },
    playDing(){
      var audio = new Audio('https://assets.brimecdn.com/brime/dink.mp3')
      audio.play();
    },
      initTwitchChat(channel) {
        const client = new tmi.Client({
        channels: [channel],
    })
    client.connect()

    client.on('message', (channel, tags, message, self) => {
      function getMessageHTML(message, { emotes }) {
        if (!emotes) return message

        // store all emote keywords
        // ! you have to first scan through
        // the message string and replace later
        const stringReplacements = []

        // iterate of emotes to access ids and positions
        Object.entries(emotes).forEach(([id, positions]) => {
          // use only the first position to find out the emote key word
          const position = positions[0]
          const [start, end] = position.split('-')
          const stringToReplace = message.substring(
            parseInt(start, 10),
            parseInt(end, 10) + 1,
          )

          stringReplacements.push({
            stringToReplace: stringToReplace,
            replacement: `<img src="https://static-cdn.jtvnw.net/emoticons/v2/${id}/default/dark/1.0">`,
          })
        })

        // generate HTML and replace all emote keywords with image elements
        const messageHTML = stringReplacements.reduce(
          (acc, { stringToReplace, replacement }) => {
            // obs browser doesn't seam to know about replaceAll
            return acc.split(stringToReplace).join(replacement)
          },
          message,
        )

        return messageHTML
      }
      let emotes = tags.emotes
      let data = {
        xid: tags['id'],
        topic: 'channel/chat/send/geeken',
        channel: 'geeken',
        user: {
          xid: '',
          legacy_id: '',
          displayname: tags['display-name'],
          username: tags['display-name'],
          color: tags['color'],
        },
        timestamp: 1631940643375,
        reply: false,
        content: {
          type: 'text',
          platform: 'twitch',
          subscriber: tags.subscriber,
          raw: getMessageHTML(message, {emotes}),
          parsed: getMessageHTML(message, {emotes}),
          rich: {},
          meta: {
            emotes: [],
            mentions: [],
            links: [],
            attachements: [],
          },
        },
      }
      this.messages.push(data)
    })
      },
    async openViewerList() {
      let chatters = await this.channelChatters(this.channel)
      const guestRemove = chatters.filter(function( obj ) {
      return obj.username !== null;
      });
      this.chatters = guestRemove.sort((a,b) => (a.username > b.username) ? 1 : ((b.username > a.username) ? -1 : 0))
      this.toggleChat()
      this.viewerListShow = true
      this.activeElement = 'VIEWER LIST'
    },
    async openSettings() {
      this.toggleChat()
      this.settingsShow = true
      this.activeElement = 'CHAT SETTINGS'
    },
    async toggleChat() {
      var messages = document.getElementsByClassName('messages')
      var input = document.getElementsByClassName('input')
      if (messages[0].style.display == 'none') {
        messages[0].style.display = 'block'
        input[0].style.display = 'flex'
      } else {
        messages[0].style.display = 'none'
        input[0].style.display = 'none'
      }
    },
    async closeViewerList() {
      this.toggleChat()
      this.viewerListShow = false
      this.settingsShow = false
      this.activeElement = 'STREAM CHAT'
    },
    async initChat() {
      const chatConnection = {
        connection: {
          host: 'chat.brime.tv',
          port: 443,
          endpoint: '/ws',
          username: 'geeken',
          password: '',
          clean: true, // Reserved session
          connectTimeout: 4000, // Time out
          reconnectPeriod: 4000, // Reconnection interval
          rejectUnauthorized: false,
        },
      }
      let chat_username = ''
      let token = ''
      if (this.$auth.loading == false && this.$auth.user) {
        chat_username = this.$auth.user.sub.replace('auth0|', '')
        token = await this.$auth.getTokenSilently()
      } else {
        token =
          Math.random()
            .toString(36)
            .substring(2, 8) +
          Math.random()
            .toString(36)
            .substring(2, 8)
        chat_username = `guest`
      }
      if (this.token) {
      chat_username = this.user
      token = this.token
      }
      const username = chat_username
      const { host, port, endpoint } = chatConnection.connection
      const connectUrl = `wss://${host}:${port}${endpoint}`
      try {
        this.client = mqtt.connect(connectUrl, { username, password: token, keepalive: 30, clean: true, reconnectPeriod: 4000, rejectUnauthorized: false })
      } catch {}
      this.client.on('connect', () => {
        this.client.subscribe(`channel/chat/receive/${this.channel}/${this.chatLang}`, err => {
          if (err) console.error(err)
        })
        this.client.subscribe(`channel/chat/receive/${this.channel}`, err => {
          if (err) console.error(err)
        })
        this.client.subscribe(`private/${username}`, err => {
          if (err) console.error(err)
        })
      })
      this.client.on('error', error => {
        console.error(error)
      })
      this.client.on('message', (topic, message) => {
        if(this.ding == true){
        this.playDing()
        }
        try {
          const data = JSON.parse(message.toString())
            if(data.type == 'delete'){
            this.messages.some(item => { 
            if(item.linked_xid === data.targetMsg) // Case sensitive, will only remove first instance
            this.messages.splice(this.messages.indexOf(item),1) 
            })
            console.log(`Deleting msg ${data.targetMsg}`)
            const msg = document.getElementById(`m__${data.targetMsg}`)
            msg.remove()
            return
          }
          this.messages.push(data)
          if(this.settings.animate){
            setTimeout(() => {
              document.getElementById(`m__${data.xid}`).classList.add('slide-out')
            }, this.settings.ttl * 1000)
          setTimeout(() => {
            this.messages.splice(this.messages.findIndex(el => el.xid === data.xid), 1);
          }, this.settings.ttl * 1000 + 500)
          }
          if (this.messages.length > this.MAX_MESSAGES) {
            this.messages.splice(0, this.messages.length - this.MAX_MESSAGES)
          }
        } catch (err) {
          console.error('Corrupted message format.')
          console.error(message)
        }
      })
    },
    sendMessage({ content }) {
      if (!this.$auth.user && !this.mobile) {
        this.$auth.loginWithRedirect()
      }
      const message = {
        content,
        reply_target: this.reply_target ? this.reply_target.xid : false,
      }
      this.removeReplyTarget()
      this.resetMentionTarget()
      this.client.publish(
        `channel/chat/send/${this.channel}`,
        JSON.stringify(message),
        { qos: 0, retain: false },
      )
    },
    sendCommand(command){
      this.client.publish(
        `channel/chat/send/${this.channel}`,
        JSON.stringify({
          content: command,
          reply_target: false
        }),
        { qos: 0, retain: false },
      )
      this.context = false
    },
    sendGif(id) {
      this.sendMessage({ content: `/gif ${id}` })
    },
    setReplyTarget(message) {
      this.reply_target = message
      this.$el.querySelector('.chat__input').focus()
    },
    scrollToReply(xid) {
      let re = document.getElementById(`m__${xid}`)
      if (!re) return
      this.highlight = xid
      this.$el.querySelector('.messages').scrollTo({
        top: re.offsetTop,
        behavior: 'smooth',
      })
      setTimeout(() => {
        if (this.highlight == xid) this.highlight = false
      }, 2500)
    },
    removeReplyTarget() {
      this.reply_target = false
    },
    setMentionTarget(user) {
      this.mention = user
      this.$el.querySelector('.chat__input').focus()
    },
    resetMentionTarget() {
      this.mention = false
    },
    setUserTarget(target) {
      this.user_target = target
    },
    setLinkTarget(target) {
      this.link_target = target
    },
    setContext(data){
      this.context = data
    },
    unsubscribe() {
      this.client.unsubscribe(`channel/chat/receive/${this.channel}`)
    },
    async rewindMessages() {
      let r = await fetch(
        `https://api.brime.tv/v1/chat/${this.channel}/history`,
      )
      let items = await r.json()
      let pmessages = []
      for (let item of items) {
        if (item.DELETED !== 1){
        pmessages.push(item.CONTENT)
        }
      }
      this.messages.unshift(...pmessages)
      this.chat.scrollTop = this.chat.offsetHeight
    },
    isVisible(ele, container) {
      const { bottom, height, top } = ele.getBoundingClientRect()
      const containerRect = container.getBoundingClientRect()

      return top <= containerRect.top
        ? containerRect.top - top <= height
        : bottom - containerRect.bottom <= height
    },
    async getChannel() {
        let channelData = await this.channelLookupByXID(this.channel)
    }
  },
  components: {
    ChatMessage,
    ChatInput,
    ChatReply,
    UserCard,
    LinkCard,
    ChatContext,
    BAvatar,
    BFormCheckbox,
  },
  async created() {
    this.initChat()
  },
  watch: {
    channel: [{
      handler: 'getChannel'
    }]
  },
  mounted() {
      this.pauseGifs = () => {
      this.$el.querySelectorAll('.gif__element').forEach(e => {
        e.src = e.dataset.static
      })
    }

    window.addEventListener('blur', this.pauseGifs)

    this.ding = window.localStorage.getItem('chat-ding');

    function debounce(func, timeout = 250) {
      let timer
      return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          func.apply(this, args)
        }, timeout)
      }
    }

    this.resizeHandler = debounce(() => {
      document.querySelector('.app-content').style = `--headerHeight: ${
        document.querySelector('.header-navbar').clientHeight
      }px;`
    }, 150)

    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler)

    /* eslint-disable */
    const chat = document.querySelector('.messages')
    this.chat = chat
    const continueChat = document.getElementById('continue-chat')

    continueChat.addEventListener('click', () => {
      chat.scrollTop = chat.scrollHeight + chat.offsetHeight
    })

    this.chatScroll = () => {
      let gifs = chat.querySelectorAll('.gif__element')
      if (document.hasFocus()) {
        for (let gif of gifs) {
          if (this.isVisible(gif, chat)) {
            gif.dataset.visible = 'true'
            if (gif.src == gif.dataset.gif) continue
            gif.src = gif.dataset.gif
            continue
          }

          gif.dataset.visible = 'false'
          if (gif.src == gif.dataset.static) continue
          gif.src = gif.dataset.static
        }
      }

      if (
        chat.scrollHeight > chat.offsetHeight &&
        chat.scrollHeight - chat.scrollTop - chat.offsetHeight > 64
      ) {
        continueChat.style.display = 'block'
        return (chat.dataset.pauseScrolling = 'true')
      }
      continueChat.style.display = 'none'
      return (chat.dataset.pauseScrolling = 'false')
    }

    chat.addEventListener('scroll', this.chatScroll)
    window.addEventListener('focus', this.chatScroll)
    window.addEventListener('beforeunload', this.unsubscribe)
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler)
    window.removeEventListener('blur', this.pauseGifs)
    window.removeEventListener('focus', this.chatScroll)
    this.chat.removeEventListener('scroll', this.chatScroll)
    this.client.unsubscribe(`channel/chat/receive/${this.channel}`)
  },
}
</script>
